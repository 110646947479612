import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const frontmatter = {
  title: 'I want to Code',
  date: '2014-01-04T22:58:59.501Z',
  draft: false
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`My name is Donnie West. I work in fast food and I am teaching myself how to code. I'm doing it not only to become more valuable as a member of the workforce, but also to instill in myself qualities I lack.`}</p>
    <h2>{`I am utterly undisciplined`}</h2>
    <p>{`Programming is a discipline where one learns to communicate clearly one's intentions to both a computer and fellow human beings; that is, the communication must be concise and logical. This is precisely why I find programming so appealing. It is the craft of the digital age, a skill to be honed over one's lifetime and built upon.`}</p>
    {
      /* more */
    }
    <p>{`I've been told my entire life that I'm a very intelligent individual, but it's incredible that people ignore the most `}<strong parentName="p">{`damning`}</strong>{` characteristic about me: I'm lazy. I lack the grit that's so necessary to carry out any semblance of success in my life and all the while have been praised about it.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Intelligence without amibition is a bird without wings
-- `}<cite>{`Salvador Dalí`}</cite></p>
    </blockquote>
    <p>{`Programming will be my means of instilling this discipline within me.`}</p>
    <h2>{`I'm afraid of Failure`}</h2>
    <p>{`Programming `}<a parentName="p" {...{
        "href": "https://medium.com/tech-talk/b4c8a249e70f"
      }}><em parentName="a">{`is failure`}</em></a>{`. If you don't fail constantly you're simply not challenging yourself enough. If you are a beginner at `}<em parentName="p">{`anything`}</em>{` you should expect to fail often. In fact, we've found that for any given skill it's best to `}<a parentName="p" {...{
        "href": "http://jamesclear.com/repetitions"
      }}>{`iterate quickly as opposed to making it perfect.`}</a></p>
    <p>{`Unfortunately, I have a mean perfectionist streak. It means I procrastinate on projects I don't know how to do. I spend endless amounts of time on tiny details. It means I don't allow myself to fail. Which is why my fear of failure is destructive to me: it holds me back from progress. It's a large part of my lack of discipline. I lack the self control to get up from my failures and try again. That's the horrifying part of fearing failure. `}<em parentName="p">{`When you fear failure, you only fail more.`}</em></p>
    <h2>{`I'm ashamed of where I am`}</h2>
    <p>{`For some time I've been haunted by the fact that I dropped out of college and lack a clear path to future success. I've carried a mental burden that I won't amount to anything because I work in fast food and don't have a degree. Therefore, I not only work at the most stereotypical place for a non-college graduate to work but also my friends have begun graduating.
And it's all sheer folly.`}</p>
    <ul>
      <li parentName="ul">{`I've avoided crushing debt`}</li>
    </ul>
    <p>{`The average Missouri college graduate (where I live) leaves school with `}<a parentName="p" {...{
        "href": "http://projectonstudentdebt.org/state_by_state-view2013.php?area=MO"
      }}>{`$23,030 in debt`}</a>{`. It would be different if college degrees are worth it, but increasingly we're seeing `}<a parentName="p" {...{
        "href": "http://www.sfgate.com/education/article/Is-college-worth-the-cost-Study-casts-doubt-4500844.php"
      }}>{`they're not.`}</a>{` We're finding that there are alternatives that are worthy of pursuit. For me the alternative is self education.`}</p>
    <ul>
      <li parentName="ul">{`My value is not determined by a degree`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`“I don’t care if you work for McDonald’s. `}{`[…]`}{` If you show up early, if you stay late, if you volunteer for the hard stuff, you’re going run that organization.”
-- `}<cite>{` Mike Rowe `}<a parentName="p" {...{
            "href": "http://foxnewsinsider.com/2013/08/04/mike-rowe-dirty-jobs-alternative-college-degree"
          }}>{`Fox News Interview`}</a></cite></p>
    </blockquote>
    <p>{`  My value is determined by the grit I wish to instill in myself.`}</p>
    <ul>
      <li parentName="ul">{`I provide for my family`}</li>
    </ul>
    <p>{`I work a full time job and I can afford to pay the bills and pay my rent with a little to save at the end of it all. It's not much, but ultimately that's what matters in my relationship to my job - that my family is provided for - as opposed to the frills or esteem of a higher paying job. I should still always strive for better but I don't `}<em parentName="p">{`have`}</em>{` to.`}</p>
    <p>{`I will put a stop to the shame. I will stop feeling sorry for where I am in life and then act to change it.`}</p>
    <h2>{`What I'm going to do about it`}</h2>
    <p>{`I have an immense difficulty doing something for such a long term. I lack the grit necessary to stick to my goals. I will change that. `}</p>
    <ul>
      <li parentName="ul">{`I've begun using the `}<a parentName="li" {...{
          "href": "http://jamesclear.com/stop-procrastinating-seinfeld-strategy"
        }}>{`Seinfeld Method`}</a>{` to ensure I code each day.`}</li>
      <li parentName="ul">{`I'm going to seek out a mentor to guide me through my self education.`}</li>
    </ul>
    <p>{`Through these, I will become disciplined by consistent practice, rid myself of my fear of failure by iterating quickly and stop being ashamed.`}</p>
    <p>{`So. What's stopping `}<strong parentName="p">{`you`}</strong>{` from doing the same?`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      